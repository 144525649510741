import React, { Suspense } from "react";
import "./OrderData.scss";
import {
  Form,
  Typography,
  Select,
  Segmented,
  Dropdown,
  Button,
  Skeleton,
  Card,
  Table,
  Pagination,
  Space,
  Checkbox,
} from "antd";
import { SettingOutlined, CheckOutlined } from "@ant-design/icons";
import useOrderData from "../../../../hooks/useOrderData";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Can from "../../../../common/services/permissions/Can";
const OrderDrawer = React.lazy(() => import("../OrderDrawer/OrderDrawer"));

export default function OrderData({
  page,
  patient,
  setPage,
  isSearchBarVisible,
  setPerPage,
  perPage,
  error,
  searchVal,
  date_start,
  date_end,
  orderBy,
  setOrderBy,
  driverFilter,
  setDriverFilter,
  locationFilter,
  setLocationFilter,
  paymentFilter,
  setPaymentFilter,
  selectedSegment,
  setSelectedSegment,
  isByMetrc,
  setIsByMetrc,
  segments,
  tabKey,
  setIsEditTabModalOpened,
  setIsDeleteTabModalOpened,
  roomsLength,
  isGettingRooms,
  setselectedTabKey,
  getOrdersWithParams
}) {
  const {
    token,
    isMobile,
    drivers,
    tableColumns,
    selectedColumns,
    handleDeselectColumns,
    handleSelectColumns,
    totalItems,
    reportLoading,
    loading,
    currentField,
    isDrawerOpened,
    rowSelection,
    results,
    onSorterIconFildChange,
    handleTableChange,
    onShowSizeChange,
    count,
    selectedOrder,
    setIsDrawerOpened,
    setSelectedOrder,
    contextHolder,
    scrollwidth,
    tableRef,
    updateOrderWhenCustomerDetailsChanged,
    success,
    metricOrdersSync,
    selectedRowKeys,
    updateReportwhenOrderItemChanged,
    roomLoading,
  } = useOrderData(
    setPage,
    patient,
    setPerPage,
    page,
    perPage,
    searchVal,
    date_start,
    date_end,
    orderBy,
    setOrderBy,
    driverFilter,
    setDriverFilter,
    locationFilter,
    setLocationFilter,
    paymentFilter,
    setPaymentFilter,
    selectedSegment,
    setSelectedSegment,
    segments,
    tabKey,
    isByMetrc,
    roomsLength,
    isGettingRooms
  );
  return (
    <>
      {contextHolder}
      <div
        className="orders-cont__content__pageCont"
        style={
          isGettingRooms
            ? {
                padding: isMobile ? "12px" : "24px",
              }
            : null
        }>
        {tabKey === "all" ? null : (
          <div className="orders-cont__content__pageCont__roomdetailWrapper">
            <Typography.Text className="orders-cont__content__pageCont__roomdetailWrapper__text">
              ID {tabKey}
            </Typography.Text>
            <Space size={8} direction="horizontal">
              <Can I="change" a="orderroom" fallback={<></>}>
                <Button
                  className="tabcomp-cont__firstRow__icon-wrappper"
                  type="text"
                  disabled={roomLoading}
                  onClick={() => {
                    setIsEditTabModalOpened(true);
                  }}
                  icon={
                    <EditOutlined className="tabcomp-cont__firstRow__icon-wrappper__icon" />
                  }
                />
              </Can>
              <Can I="delete" a="orderroom" fallback={<></>}>
                <Button
                  className="tabcomp-cont__firstRow__icon-wrappper"
                  type="text"
                  disabled={roomLoading}
                  icon={
                    <DeleteOutlined className="tabcomp-cont__firstRow__icon-wrappper__icon" />
                  }
                  onClick={() => {
                    setIsDeleteTabModalOpened(true);
                  }}
                />
              </Can>
            </Space>
          </div>
        )}

        <div className="orders-cont__content__pageCont__segmentWrapper">
          {isMobile ? (
            <Form
              style={{
                width: "100%",
                maxWidth: "900px",
              }}
              layout="vertical">
              <Form.Item label={<Typography.Text>Status</Typography.Text>}>
                <Select
                  value={selectedSegment}
                  size="large"
                  options={segments}
                  onChange={(val) => {
                    setSelectedSegment(val);
                    setPage(1);
                  }}
                />
              </Form.Item>
            </Form>
          ) : (
            <div className="orders-cont__content__pageCont__segmentWrapper__withCheckbox">
              <Segmented
                options={segments}
                value={selectedSegment}
                onChange={(val) => {
                  setSelectedSegment(val);
                  setPage(1);
                }}
              />
              {tabKey === "all" ? (
                <>
                  <Checkbox
                    checked={isByMetrc}
                    onChange={() => {
                      setIsByMetrc(!isByMetrc);
                    }}>
                    Metrc
                  </Checkbox>
                  <Can I="change" a="orderroom" fallback={<></>}>
                    {selectedRowKeys?.length ? (
                      <Button
                        className=""
                        type="primary"
                        loading={roomLoading}
                        onClick={() => {
                          metricOrdersSync();
                        }}>
                        Report To Metrc
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Can>
                </>
              ) : null}
            </div>
          )}
          {isMobile && tabKey === "all" ? (
            <Checkbox
              style={{
                marginBottom: "10px",
              }}
              checked={isByMetrc}
              onChange={() => {
                setIsByMetrc(!isByMetrc);
              }}>
              Metrc
            </Checkbox>
          ) : null}
          <Dropdown
            menu={{
              items: tableColumns
                .filter((item) => item.key !== "icons")
                .map((item) => {
                  return {
                    label: item.title,
                    key: item.key,
                    icon:
                      selectedColumns.indexOf(item.key) >= 0 ? (
                        <CheckOutlined />
                      ) : null,
                    style: { flexDirection: "row-reverse" },
                  };
                }),
              selectable: true,
              onDeselect: handleDeselectColumns,
              onSelect: handleSelectColumns,
              selectedKeys: selectedColumns,
            }}
            placement="bottom"
            arrow>
            <Button>
              <SettingOutlined />
            </Button>
          </Dropdown>
        </div>
        {isMobile || tabKey !== "all" ? null : (
          <div className="orders-cont__content__pageCont__cardCont">
            {totalItems.map((el) => (
              <Card
                key={el?.id}
                className="orders-cont__content__pageCont__cardCont__card"
                // hoverable
                style={{
                  borderLeftColor: `${token?.Card?.colorPrimary}`,
                }}
                title={
                  <div className="orders-cont__content__pageCont__cardCont__card__titleLine">
                    <Typography.Text className="orders-cont__content__pageCont__cardCont__card__titleLine__title">
                      {el?.title}
                    </Typography.Text>
                    {el?.month ? (
                      <Typography.Text className="orders-cont__content__pageCont__cardCont__card__titleLine__month">
                        {el?.month}
                      </Typography.Text>
                    ) : null}
                  </div>
                }>
                {reportLoading ? (
                  <Skeleton
                    active
                    title={{
                      width: "100%",
                    }}
                    paragraph={false}
                  />
                ) : (
                  <div className="orders-cont__content__pageCont__cardCont__card__body">
                    {el?.icon}
                    {el?.bodyIcon ? (
                      <div className="orders-cont__content__pageCont__cardCont__card__body__wrapper">
                        {el?.bodyIcon}
                        <Typography.Text
                          className="orders-cont__content__pageCont__cardCont__card__body__wrapper__text"
                          style={{
                            color: token?.Typography?.baseColorText,
                          }}>
                          {el?.bodyText}
                        </Typography.Text>
                      </div>
                    ) : (
                      <Typography.Text
                        className="orders-cont__content__pageCont__cardCont__card__body__text"
                        style={{
                          color: token?.Typography?.baseColorText,
                        }}>
                        {el?.bodyText}
                      </Typography.Text>
                    )}
                  </div>
                )}
              </Card>
            ))}
          </div>
        )}
        <div className="orders-cont__content__pageCont__tableCont" key={tabKey}>
          <Table
            className="orders-cont__content__pageCont__tableCont__table"
            bordered
            ref={tableRef}
            loading={loading && currentField === "" && !isDrawerOpened}
            columns={tableColumns?.filter(
              (item) => selectedColumns?.indexOf(item.key) >= 0
            )}
            rowSelection={rowSelection}
            dataSource={results?.map((item) => {
              const driver = drivers?.find((el) => el?.id === item?.driver);
              return {
                ...item,
                key: item.id,
                // driver: driver
                //   ? `${driver?.first_name} ${driver?.last_name}`
                //   : null,
                total: `$ ${item?.grand_total}`,
              };
            })}
            pagination={false}
            onChange={onSorterIconFildChange}
            onRow={(record) => {
              return isDrawerOpened && record?.key === selectedOrder?.id
                ? {
                    style: {
                      backgroundColor: token?.colorPrimary + "1A",
                    },
                  }
                : record.new
                ? {
                    style: {
                      backgroundColor: token?.colorPrimary + "3A",
                    },
                  }
                : "";
            }}
            scroll={{
              x: `${scrollwidth}px`,
            }}
          />
          <Pagination
            className="orders-cont__content__pageCont__tableCont__pagination"
            pageSize={perPage}
            current={page}
            onChange={handleTableChange}
            onShowSizeChange={onShowSizeChange}
            total={count}
            responsive={true}
            showQuickJumper={true}
          />
        </div>
        {isDrawerOpened && (
          <Suspense fallback={<></>}>
            <OrderDrawer
              setselectedTabKey={setselectedTabKey}
              getOrdersWithParams={getOrdersWithParams}
              isOpened={isDrawerOpened}
              order={selectedOrder}
              close={() => {
                setIsDrawerOpened(false);
                setSelectedOrder(null);
              }}
              isLoading={loading}
              error={error}
              isSearchBarVisible={isSearchBarVisible}
              updateOrderWhenCustomerDetailsChanged={
                updateOrderWhenCustomerDetailsChanged
              }
              orderSuccess={success}
              updateReportwhenOrderItemChanged={
                updateReportwhenOrderItemChanged
              }
            />
          </Suspense>
        )}
      </div>
    </>
  );
}
