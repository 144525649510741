import "./EditMenu.scss";
import { Typography, Modal, Input, Form, Select } from "antd";
import useEditMenuModal from "../../hooks/useEditMenuModal";
import { updateMenu } from "../../features/menu/menuActions";
import { useEffect } from "react";
import { resetErrors } from "../../features/menu/menuSlice";

export default function EditMenu({
  isOpened,
  title,
  subTitle,
  menuName,
  // onOk,
  onCancel,
  pageName,
}) {
  const { modal, button, formRef, dispatch, loading, error } =
    useEditMenuModal();
 
    const edit = (id, updated_name) => {
    return dispatch(updateMenu({ id, updated_name }));
  };

  const onOk = async (id, updated_name) => {
    const data = await edit(id, updated_name);

    if (data.type === "updateMenu/fulfilled") {
      onCancel();
      formRef?.current?.setFields([
        {
          name: "name",
          value: "",
          errors: [],
        }
      ]);
    }
  };

  useEffect(() => {
    if (error && !loading) {
      for (let key in error) {
        formRef?.current?.setFields([
          {
            name: key,
            errors: [error[key][0]],
          },
        ]);
      }
    }
  }, [loading, error, isOpened]);

  return (
    <Modal
      open={isOpened}
      title={title}
      onOk={() => {
        formRef.current.submit();
      }}
      okText="Save"
      closeIcon={false}
      centered
      onCancel={() => {
        onCancel();
        dispatch(resetErrors());
        formRef?.current?.setFields([
          {
            name: "name",
            value: "",
            errors: [],
          }
        ]);
      }}
      style={{
        borderTop: `2px solid ${button?.colorPrimary}`,
      }}
      className="editmenu-cont"
      cancelButtonProps={{
        className: "editmenu-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        htmlType: "submit",
        type: "primary",
        className: "editmenu-cont__btn",
      }}
    >
      <Form
        layout="vertical"
        ref={formRef}
        className="editmenu-cont__form"
        onFinish={(values) => {
          onOk(pageName[0], values["name"]);
        }}
        onFinishFailed={(error) => {
        }}
      >
        <Form.Item
          name="name"
          className="editmenu-cont__form__formItem"
          rules={[
            {
              required: true,
              message: "edit name can't be empty",
            },
          ]}
          initialValue={{ value: menuName?.toUpperCase(), label: menuName?.toUpperCase() }}
          colon={false}
          label={
            <Typography.Text
              className="editmenu-cont__form__formItem__text"
              style={{
                color: `${modal.titleColor}`,
              }}
            >
              {subTitle}
            </Typography.Text>
          }
        >
          <Select placeholder="Select"
            options={[
              { label: "WEEDMAPS", value: "WEEDMAPS" },
              // { label: "WEEDVADER", value: "WEEDVADER" },
              { label: "WORDPRESS", value: "WORDPRESS" },
              { label: "LEAFLY", value: "LEAFLY" },
            ]}
            allowClear
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
