import {
  theme,
  Image,
  Typography,
  Divider,
  notification,
  Button,
  Input,
  Skeleton,
  Select,
} from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axiosClient from "../common/api/request";
// import config from "../config";
import { getDrivers } from "../features/driver/driverActions";
import {
  updateGrandTotal,
  updateOrderDriver,
  updateOrderPaymentType,
  updateOrderStatus,
  patchOrderItems,
  patchOrder,
  patchOrderPromotionCode,
  patchCustomerNote,
} from "../features/orders/ordersActions";
import { constrolLoadingState_betweenCustomer_order } from "../features/orders/ordersSlice";
import { getItems, getSubMenus } from "../features/menu/menuActions";
import {
  postPatientShippingAddress,
  getPatientShippingAddresses,
  updatePatient,
  patchPatientShippingAddress,
} from "../features/patient/patientActions";
import { resetError } from "../features/orders/ordersSlice";
import useWindowWidth from "./useWindowWidth";
import Utils from "../utils";
import { CustomSelect } from "../components";
import {
  EditTwoTone,
  CloseCircleOutlined,
  CheckCircleTwoTone,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import { resetError as resetPatientError } from "../features/patient/patientSlice";
import { Link } from "react-router-dom";

export default function useOrderDrawer(
  order,
  close,
  isOpened,
  isLoading,
  getOrdersWithParams,
  setselectedTabKey,
  // error,
  updateOrderWhenCustomerDetailsChanged,
  // orderSuccess,
  updateReportwhenOrderItemChanged
) {
  const { useToken } = theme;
  const { token } = useToken();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, title, text) => {
    api[type]({
      message: title,
      description: text,
    });
  };
  const [dropdownItemsDriver, setDropdownItemsDriver] = useState([]);
  const { options } = useSelector((state) => state?.paymentTypes);
  const { isMobile, isSmallDesktop } = useWindowWidth();
  const dropdownItemsActions = [
    {
      key: "IN_PROGRESS",
      label: Utils?.statusNameMapping("IN_PROGRESS"),
    },
    {
      key: "READY_FOR_ATTAINMENT",
      label: Utils?.statusNameMapping("READY_FOR_ATTAINMENT"),
    },
    {
      key: "COMPLETE",
      label: Utils?.statusNameMapping("COMPLETE"),
    },
    {
      key: "CANCELED_CUSTOMER",
      label: Utils?.statusNameMapping("CANCELED_CUSTOMER"),
    },
  ];
  const dropdownItemsCards = options?.length ? options : [];
  const {
    loading_state_betwCustomer_order: globalLoading,
    successUpdatedOrderItem,
    success_update_promotionCode,
    error,
    loading: orderLoading,
  } = useSelector((state) => state?.orders);
  const [isEditTotalOPened, setIsEditTotalOpened] = useState(false);
  const [inpVal, setInpVal] = useState("");
  const [assignedDriver, setAssignedDriver] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [currentField, setCurrentField] = useState("");
  const [secondCollapseActiveKey, setSecondCollapseActiveKey] = useState([
    "item",
  ]);
  const [firstCollapseActiveKey, setFirstCollapseActiveKey] = useState([
    "table",
  ]);
  const [thirthCollapseActiveKey, setThirthCollapseActiveKey] = useState([]);
  const [is_note_text_area_visible, set_is_note_text_area_visible] =
    useState(false);
  const [note, setNote] = useState("");
  const {
    loading,
    success,
    error: patientError,
    shipping_addresses: { results: addresses },
  } = useSelector((state) => state?.patient);
  const { possibleDiscounts } = useSelector((state) => state?.discounts);
  const {
    menuItems: { meta: metaMenuItem, results: menuitems },
    loading: menuItemLoading,
  } = useSelector((state) => state?.menu);
  const [selectedRowKey, setselectedRowKey] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [receivedMenuItems, setReceivedMenuItems] = useState([]); //same as menuitems in createorder
  const [searchMenuItemVal, setSearchMenuItemVal] = useState(""); //smae as searchitemval
  const [page, setPage] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [menuItemCount, setMenuItemCount] = useState("");
  const { results: menus = [] } = useSelector((state) => state.menu.menu);
  const { results: subMenus } = useSelector((state) => state.menu.subMenu);
  const [isDeleteOrderItemModalOpened, setIsDeleteOrderItemModalOpened] =
    useState(false);
  const [isBlackListModalOpened, setIsBlackListModalOpened] = useState(false);
  const [isConfirmOpened, setIsConfirmOpened] = useState(false);

  const handleSearchMenuItem = Utils?.debounce((val) => {
    setSearchMenuItemVal(val.toLowerCase());
  }, 500);

  useEffect(() => {
    selectedMenu?.id && dispatch(getSubMenus(selectedMenu?.id));
  }, [selectedMenu?.id]);

  const [orderItemMode, setOrderItemMode] = useState("");
  const uniqueItems = [
    ...new Map(receivedMenuItems?.map((item) => [item["id"], item])).values(),
  ];

  useEffect(() => {
    if (selectedSubMenu?.id) {
      if (searchMenuItemVal) {
        dispatch(
          getItems({
            name: searchMenuItemVal,
            sub_menu: selectedSubMenu?.id,
          })
        );
        setReceivedMenuItems([]);
        setPage(1);
      } else {
        dispatch(
          getItems({
            sub_menu: selectedSubMenu?.id,
          })
        );
        setPage(1);
        setReceivedMenuItems([]);
      }
    }
  }, [searchMenuItemVal, selectedSubMenu?.id]);

  useEffect(() => {
    if (searchMenuItemVal) {
      if (page === 1) {
        setReceivedMenuItems(menuitems);
      } else {
        setReceivedMenuItems([...receivedMenuItems, ...menuitems]);
      }
    } else {
      if (page === 1) {
        setReceivedMenuItems(menuitems);
      } else {
        setReceivedMenuItems([...receivedMenuItems, ...menuitems]);
      }
    }
  }, [menuitems]);

  const handleAddItem = () => {
    const currentTime = Date.now(); // Get current time in milliseconds
    const randomNumber = Math.floor(Math.random() * 1000) + 1; // Generate random number between 1 and 1000
    const uniqueNumber = currentTime + randomNumber;
    const newItem = {
      image: null,
      name: null,
      count: null,
      unit: null,
      price: "",
      key: uniqueNumber,
      locale: true,
    };
    setTableData([newItem, ...tableData]);
    setselectedRowKey(uniqueNumber);
    setOrderItemMode("edit");
  };

  const drivers = useSelector((state) => state?.driver?.drivers?.results);

  const updateOrderItems = (params) => {
    dispatch(
      patchOrderItems({
        order_items: params?.order_items,
        id: order?.id,
      })
    );
  };

  const deleteOrderItem = (params) => {
    dispatch(patchOrderItems(params));
  };

  useEffect(() => {
    if (isOpened) {
      if (successUpdatedOrderItem) {
        dispatch(resetError());
        setselectedRowKey(null);
        setSelectedMenuItem(null);
        setSelectedVariant(null);
        setMenuItemCount(null);
        setOrderItemMode("");
        setSearchMenuItemVal("");
        updateReportwhenOrderItemChanged();
      }
    }
  }, [successUpdatedOrderItem]);

  const OrderIsNotEditable =
    Utils?.statusNameMapping(order?.status) === "Canceled" ? true : false;

  const tableColumns = OrderIsNotEditable
    ? [
        {
          title: "",
          dataIndex: "image",
          key: "image",
          width: 56,
          className:
            "addBag-cont__tableWrapper__table__header-cell-small  table-row-text",
          render: (_, record) => {
            return (
              <Image src={record.image} alt="plan" className="addBag-image" />
            );
          },
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          className: "table-row-text",
        },
        {
          title: "Count",
          dataIndex: "count",
          key: "count",
          className: "table-row-text",
        },
        {
          title: "Unit",
          dataIndex: "unit",
          key: "unit",
          className: "table-row-text",
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
          className: "tableCelltext-right",
          render: (_, record) => {
            return record?.price ? Utils?.roundNumber(record?.price) : "$";
          },
        },
      ]
    : [
        {
          title: "",
          dataIndex: "image",
          key: "image",
          width: 56,
          className:
            "addBag-cont__tableWrapper__table__header-cell-small  table-row-text",
          render: (_, record) => {
            return selectedRowKey === record?.key &&
              selectedMenuItem &&
              orderItemMode === "edit" ? (
              <Image
                src={selectedMenuItem?.picture_url}
                alt="CA"
                className="addBag-image"
              />
            ) : selectedRowKey === record?.key &&
              !selectedMenuItem &&
              orderItemMode === "edit" ? null : (
              <Image src={record?.image} alt="CA" className="addBag-image" />
            );
          },
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          className: "table-row-text",
          ellipsis: true,
          width: selectedRowKey && orderItemMode === "edit" ? 358 : 330,

          render: (_, record) => {
            return record?.key === selectedRowKey &&
              orderItemMode === "edit" ? (
              <>
                <Select
                  loading={loading}
                  allowClear
                  onChange={(optionId) => {
                    const foundItem = menus?.find((el) => el?.id === optionId);
                    setSelectedMenu(foundItem);
                    setSelectedSubMenu(null);
                  }}
                  style={{
                    width: "80px",
                  }}
                  popupMatchSelectWidth={120}
                  filterOption={false}
                  options={menus?.map((el) => ({
                    label: el?.brand?.label
                      ? `${el?.brand?.label}-${el?.name}`
                      : `${el?.name}`,
                    value: el?.id,
                  }))}
                  placeholder="Menu"
                />
                <Select
                  loading={loading}
                  allowClear
                  onChange={(optionId) => {
                    const foundItem = subMenus?.find(
                      (el) => el?.id === optionId
                    );
                    setSelectedSubMenu(foundItem);
                  }}
                  filterOption={false}
                  options={subMenus?.map((el) => ({
                    label: el?.brand?.label
                      ? `${el?.brand?.label}-${el?.name}`
                      : `${el?.name}`,
                    value: el?.id,
                  }))}
                  style={{
                    width: "80px",
                  }}
                  popupMatchSelectWidth={120}
                  placeholder="Sub Menu"
                />
                <Select
                  placeholder="item name"
                  style={{
                    width: "190px",
                  }}
                  popupMatchSelectWidth={isMobile ? 350 : 500}
                  value={selectedMenuItem?.name}
                  loading={menuItemLoading}
                  allowClear
                  showSearch
                  onClear={() => {
                    setSelectedMenuItem(null);
                    setSearchMenuItemVal("");
                    if (selectedVariant) {
                      setSelectedVariant(null);
                    }
                  }}
                  onSearch={handleSearchMenuItem}
                  onChange={(optionId) => {
                    const foundItem = uniqueItems?.find(
                      (el) => el?.id === optionId
                    );
                    if (selectedVariant) {
                      setSelectedVariant(null);
                    }
                    setSelectedMenuItem(foundItem);
                  }}
                  filterOption={false}
                  options={uniqueItems?.map((el) => ({
                    label: el?.brand?.label
                      ? `${el?.brand?.label}-${el?.name}`
                      : `${el?.name}`,
                    value: el?.id,
                  }))}
                  onPopupScroll={(e) => {
                    const { target } = e;
                    if (
                      target.scrollTop + target.clientHeight ===
                      target.scrollHeight
                    ) {
                      if (searchMenuItemVal) {
                        if (metaMenuItem?.number_of_pages > page) {
                          setPage(page + 1);
                          dispatch(
                            getItems({
                              page: page + 1,
                              per_page: 10,
                              name: searchMenuItemVal,
                              sub_menu: selectedSubMenu?.id,
                            })
                          );
                        }
                      } else {
                        if (metaMenuItem?.number_of_pages > page) {
                          setPage(page + 1);
                          dispatch(
                            getItems({
                              page: page + 1,
                              per_page: 10,
                              sub_menu: selectedSubMenu?.id,
                            })
                          );
                        }
                      }
                    }
                  }}
                />
              </>
            ) : (
              record?.name
            );
          },
        },
        {
          title: "Unit",
          dataIndex: "unit",
          key: "unit",
          className: "table-row-text",
          width: selectedRowKey && orderItemMode === "edit" ? 137 : 120,
          ellipsis: true,
          render: (_, record) => {
            return record?.key === selectedRowKey &&
              orderItemMode === "edit" ? (
              <Select
                placeholder="Unit"
                style={{
                  width: "100%",
                }}
                value={selectedVariant?.name}
                options={selectedMenuItem?.variants?.map((el) => ({
                  label: el?.name ? el.name : "There is no weight",
                  value: el?.id,
                }))}
                onChange={(variantId) => {
                  const foundVariant = selectedMenuItem?.variants?.find(
                    (el) => el?.id === variantId
                  );
                  setSelectedVariant(foundVariant);
                }}
              />
            ) : (
              record?.unit
            );
          },
        },
        {
          title: "Count",
          dataIndex: "count",
          key: "count",
          className: "table-row-text",
          // width: selectedRowKey && orderItemMode === "edit" ? 110 : null,
          width: selectedRowKey && orderItemMode === "edit" ? 90 : 100,
          ellipsis: true,
          render: (_, record) => {
            return record?.key === selectedRowKey &&
              orderItemMode === "edit" ? (
              <Input
                placeholder="Count"
                style={{
                  width: "100%",
                }}
                value={menuItemCount}
                onChange={(e) => {
                  // Check if the input value is a positive integer
                  if (!isNaN(e.target.value)) {
                    if (Number(e.target.value > 0 || e.target.value === "")) {
                      setMenuItemCount(e.target.value);
                    }
                  }
                }}
              />
            ) : (
              record?.count
            );
          },
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
          className: "tableCelltext-right",
          ellipsis: true,
          render: (_, record) => {
            return selectedRowKey === record?.key &&
              selectedVariant &&
              orderItemMode === "edit"
              ? `${Utils?.roundNumber(selectedVariant?.price)}`
              : Utils?.roundNumber(record?.price);
          },
        },
        {
          title: "",
          dataIndex: "icon",
          key: "icon",
          className: "alldrivers-cont__table__icons",
          fixed: "right",
          render: (_, record) => {
            return record?.key === selectedRowKey &&
              orderItemMode === "edit" ? (
              <div className="order-drawer__table__iconWrapper">
                <Button
                  type="text"
                  size="small"
                  disabled={
                    !selectedMenuItem || !selectedVariant || !menuItemCount
                  }
                  onClick={() => {
                    let { order_items } = order;
                    if (record?.locale) {
                      const newItems = {
                        ...order,
                        order_items: [
                          ...order_items,
                          {
                            menu_item: selectedMenuItem?.id,
                            variant: selectedVariant,
                            quantity: menuItemCount,
                          },
                        ],
                      };
                      // calculateOrder(
                      //   {
                      //     order_items: [
                      //       ...newItems?.order_items?.map((el) => ({
                      //         variant_id: el?.variant?.id,
                      //         quantity: el?.quantity,
                      //       })),
                      //     ],
                      //   },
                      //   newItems
                      // );
                      updateOrderItems(newItems);
                    } else {
                      // updateOrderItems({ ...order, order_items: newOrder_items });
                      let newOrder_items = order_items?.map((el) => {
                        if (el?.id === selectedRowKey) {
                          return {
                            menu_item: selectedMenuItem?.id,
                            variant: selectedVariant,
                            quantity: menuItemCount,
                          };
                        } else {
                          return el;
                        }
                      });
                      // calculateOrder(
                      //   {
                      //     order_items: [
                      //       ...newOrder_items?.map((el) => ({
                      //         variant_id: el?.variant?.id,
                      //         quantity: el?.quantity,
                      //       })),
                      //     ],
                      //   },
                      //   { ...order, order_items: newOrder_items }
                      // );
                      updateOrderItems({
                        ...order,
                        order_items: newOrder_items,
                      });
                    }

                    // setselectedRowKey(null);
                    // setSelectedMenuItem(null);
                    // setSelectedVariant(null);
                    // setMenuItemCount(null);
                    // setOrderItemMode("");
                    // setSearchMenuItemVal("");
                  }}
                  icon={
                    <CheckCircleTwoTone
                      twoToneColor={token?.icons?.baseColor}
                      style={{
                        fontSize: "18px",
                      }}
                    />
                  }
                />
                <Button
                  type="text"
                  size="small"
                  onClick={() => {
                    if (record?.locale) {
                      const filtredData = tableData?.filter(
                        (el) => el?.key !== record?.key
                      );
                      setTableData(filtredData);
                    }
                    setselectedRowKey(null);
                    setSelectedMenuItem(null);
                    setSelectedVariant(null);
                    setMenuItemCount(null);
                    setOrderItemMode("");
                    setSearchMenuItemVal("");
                  }}
                  icon={
                    <CloseCircleOutlined
                      className="alldrivers-cont__icons__iconWrapper__btn__EditOutlined"
                      style={{
                        fontSize: "18px",
                      }}
                    />
                  }
                />
              </div>
            ) : (
              <div className="order-drawer__table__iconWrapper">
                <Button
                  size="small"
                  type="text"
                  onClick={() => {
                    setOrderItemMode("edit");
                    setselectedRowKey(record?.key);
                    setSelectedMenuItem(null);
                    setSelectedVariant(null);
                    setSearchMenuItemVal(null);
                    setMenuItemCount("");
                  }}
                  icon={
                    <EditOutlined
                      style={{
                        fontSize: "18px",
                      }}
                    />
                  }
                />
                <Button
                  size="small"
                  type="text"
                  onClick={() => {
                    const filteredData = tableData?.filter((el) => !el?.locale);
                    if (
                      filteredData?.length <= 1 &&
                      !tableData?.find((el) => el?.key === record?.key)?.locale
                    ) {
                      openNotificationWithIcon(
                        "warning",
                        "An order must have at least one item."
                      );
                    } else {
                      setselectedRowKey(record?.key);
                      setIsDeleteOrderItemModalOpened(true);
                      setOrderItemMode("delete");
                    }
                  }}
                  icon={
                    <DeleteOutlined
                      style={{
                        fontSize: "18px",
                      }}
                    />
                  }
                />
              </div>
            );
          },
        },
      ];

  const [updatedField, setupdatedField] = useState(null);
  const [information, setInforMation] = useState(null);
  const [tableData, setTableData] = useState();
  const [editOrderDetails, setEditOrderDetails] = useState({
    Address: false,
    "Phone Number": false,
    "Promotion Code": false,
  });

  const [valOfOrderDetails, setValsOfOrderDetails] = useState({
    Address: "",
    "Phone Number": order?.customer?.phone_number
      ? order?.customer?.phone_number
      : "+1",
    "Promotion Code": order?.discount ? order?.discount : null,
  });

  const getAddressMapsUrl = (address) => {
    return `http://maps.apple.com/?q=${encodeURIComponent(address)}`;
  };

  const handleEditOrderDetails = (key) => {
    setEditOrderDetails({
      ...editOrderDetails,
      [key]: !editOrderDetails[key],
    });
  };

  const handleValsOfOrderDetails = (key, val) => {
    setValsOfOrderDetails({
      ...valOfOrderDetails,
      [key]: val,
    });
  };

  const updateOrderDetails = (params) => {
    dispatch(updatePatient(params));
    dispatch(
      constrolLoadingState_betweenCustomer_order({
        isLoading: true,
      })
    );
  };

  useEffect(() => {
    if (isOpened) {
      if (patientError && !loading) {
        for (let key in patientError) {
          openNotificationWithIcon("error", key, patientError[key][0]);
        }
        dispatch(
          constrolLoadingState_betweenCustomer_order({
            isLoading: false,
          })
        );
      }
    }
  }, [patientError, loading]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        if (updatedField === "Address") {
          dispatch(
            patchOrder({
              id: order?.id,
              address: `${valOfOrderDetails[updatedField]?.address1} ${valOfOrderDetails[updatedField]?.city} ${valOfOrderDetails[updatedField]?.state} ${valOfOrderDetails[updatedField]?.zipcode}`,
            })
          );
        } else {
          updateOrderWhenCustomerDetailsChanged();
        }
      }
    }
  }, [success]);

  useEffect(() => {
    if (isOpened) {
      if (success && updatedField && !globalLoading && !loading) {
        dispatch(resetPatientError());
        handleEditOrderDetails(updatedField);
        setValsOfOrderDetails({
          ...valOfOrderDetails,
          [updatedField]:
            updatedField === "Phone Number"
              ? order?.customer?.phone_number
              : updatedField === "Promotion Code"
              ? order?.discount
              : "",
        });
        setupdatedField(null);
      }
    }
  }, [success, isLoading, updatedField, globalLoading, loading]);

  const updateShippingAddress = (params) => {
    dispatch(
      constrolLoadingState_betweenCustomer_order({
        isLoading: true,
      })
    );
    dispatch(patchPatientShippingAddress(params));
  };

  const addShippingAddress = (params) => {
    setupdatedField(null);
    dispatch(
      postPatientShippingAddress({
        ...params,
        country: "USA",
        default: false,
        patient: order?.customer?.id,
        address2: null,
        id: order?.customer?.id,
      })
    );
  };

  const updateOrderPromotionCode = (params) => {
    dispatch(patchOrderPromotionCode(params));
  };

  useEffect(() => {
    if (isOpened) {
      if (success_update_promotionCode && !isLoading) {
        dispatch(resetError());
        handleEditOrderDetails(updatedField);
        setValsOfOrderDetails({
          ...valOfOrderDetails,
          [updatedField]:
            updatedField === "Phone Number"
              ? order?.customer?.phone_number
              : updatedField === "Promotion Code"
              ? order?.discount
              : "",
        });
        setupdatedField(null);
        updateReportwhenOrderItemChanged();
      }
    }
  }, [success_update_promotionCode, isLoading]);

  useEffect(() => {
    if (isOpened) {
      if (error && !isLoading && !success_update_promotionCode) {
        if (error && error?.discount) {
          openNotificationWithIcon("error", "discount", error["discount"][0]);
        }
      }
    }
  }, [error, isLoading]);

  const objectToArray = (obj) => {
    let res = [];
    for (let key in obj) {
      if (typeof obj[key] !== "object" && obj[key] !== null) {
        let element;
        if (key === "Id image" || key === "Medical image") {
          element = (
            <div
              className="order-drawer__collapseCont__collapse__ifo-cont"
              key={key}>
              <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine">
                <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__key-cont">
                  <Typography.Text className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__key-cont__title">
                    {key?.charAt(0).toUpperCase() + key?.slice(1).toLowerCase()}
                  </Typography.Text>
                </div>
                <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-cont">
                  {obj[key] && obj[key] !== "-" ? (
                    <Image
                      src={obj[key]}
                      width={44}
                      height={44}
                      alt="id image"
                    />
                  ) : (
                    obj[key]
                  )}
                </div>
              </div>
              {key !== "Excise Tax" ? (
                <Divider
                  className="order-drawer__collapseCont__collapse__ifo-cont__divider"
                  type="horizontal"
                />
              ) : null}
            </div>
          );
        } else {
          element = (
            <div
              className="order-drawer__collapseCont__collapse__ifo-cont"
              key={key}>
              <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine">
                <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__key-cont">
                  <Typography.Text className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__key-cont__title">
                    {key?.charAt(0).toUpperCase() + key?.slice(1).toLowerCase()}
                  </Typography.Text>
                </div>
                <div
                  className={classNames(
                    "order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-cont",
                    {
                      "order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-cont-withIcon":
                        key === "Address" ||
                        key === "Phone Number" ||
                        key === "Promotion Code",
                    }
                  )}>
                  {key === "Address" || key === "Phone Number" ? (
                    <>
                      {editOrderDetails[key] ? (
                        <>
                          {key === "Address" ? (
                            (loading || globalLoading) &&
                            updatedField === "Address" ? (
                              <Skeleton.Input
                                className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-cont-withIcon__sekeletonInp"
                                style={{
                                  width: "100%",
                                }}
                                active
                                size={"small"}
                              />
                            ) : (
                              <CustomSelect
                                addBtnText="Add"
                                placeholder="Shipping address"
                                options={addresses}
                                addItem={addShippingAddress}
                                loading={globalLoading}
                                setSelectedItem={(val) => {
                                  handleValsOfOrderDetails(key, val);
                                }}
                                selectedItem={valOfOrderDetails[key]}
                                error={
                                  patientError && !patientError?.phone_number
                                    ? patientError
                                    : null
                                }
                                resetError={() => {
                                  dispatch(resetPatientError());
                                }}
                              />
                            )
                          ) : (loading || globalLoading) &&
                            updatedField === "Phone Number" ? (
                            <Skeleton.Input
                              className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-cont-withIcon__sekeletonInp"
                              style={{
                                width: "100%",
                              }}
                              active
                              size={"small"}
                            />
                          ) : (
                            <Input
                              placeholder={key}
                              size="small"
                              value={valOfOrderDetails[key]}
                              status={
                                patientError?.phone_number ? "error" : null
                              }
                              onChange={(e) => {
                                if (patientError?.phone_number) {
                                  dispatch(resetPatientError(["phone_number"]));
                                }
                                if (key === "Phone Number") {
                                  if (!isNaN(e.target.value)) {
                                    if (
                                      Number(
                                        e.target.value > 0 ||
                                          e.target.value === ""
                                      )
                                    ) {
                                      let inputValue = e.target.value.trim();

                                      // Ensure the initial '1' is always present
                                      if (!/^\+?1/.test(inputValue)) {
                                        const remainedval = inputValue?.slice(
                                          1,
                                          inputValue?.length
                                        );
                                        inputValue = "+1" + remainedval;
                                      } else {
                                        inputValue = inputValue.replace(
                                          /^\+?1/,
                                          ""
                                        );
                                        inputValue =
                                          "+1" + inputValue.replace(/\D/g, "");
                                      }
                                      handleValsOfOrderDetails(
                                        key,
                                        inputValue === "" ? "+1" : inputValue
                                      );
                                    }
                                  }
                                } else {
                                  handleValsOfOrderDetails(key, e.target.value);
                                }
                              }}
                            />
                          )}
                          <Button
                            type="text"
                            size="small"
                            onClick={() => {
                              if (key === "Phone Number") {
                                if (patientError) {
                                  dispatch(resetPatientError(["phone_number"]));
                                }
                                const phoneNumber = order?.customer
                                  ?.phone_number
                                  ? order?.customer?.phone_number
                                  : "+1";
                                handleValsOfOrderDetails(key, phoneNumber);
                              } else if (key === "Address") {
                                if (
                                  patientError &&
                                  !patientError?.phone_number
                                ) {
                                  dispatch(resetPatientError());
                                }
                                handleValsOfOrderDetails(key, "");
                              }
                              handleEditOrderDetails(key);
                            }}
                            disabled={isLoading || loading}
                            icon={
                              <CloseCircleOutlined
                                style={{
                                  fontSize: "18px",
                                }}
                              />
                            }
                          />
                          <Button
                            type="text"
                            size="small"
                            disabled={
                              key === "Phone Number"
                                ? isLoading ||
                                  loading ||
                                  patientError?.phone_number ||
                                  valOfOrderDetails[key]?.length < 3
                                : isLoading ||
                                  loading ||
                                  (patientError &&
                                    !patientError?.phone_number) ||
                                  !valOfOrderDetails[key]
                            }
                            icon={
                              <CheckCircleTwoTone
                                twoToneColor={token?.icons?.baseColor}
                                onClick={() => {
                                  if (key === "Phone Number") {
                                    updateOrderDetails({
                                      id: order?.customer?.id,
                                      phone_number: valOfOrderDetails[key],
                                    });
                                  } else if (key === "Address") {
                                    updateShippingAddress({
                                      addressId: valOfOrderDetails[key]?.id,
                                      default: true,
                                      id: order?.customer?.id,
                                    });
                                  }
                                  setupdatedField(key);
                                }}
                                style={{
                                  fontSize: "18px",
                                }}
                              />
                            }
                          />
                        </>
                      ) : obj[key] !== "-" ? (
                        <Typography.Link
                          className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-cont__title"
                          style={
                            (key === "Address" || key === "Phone Number") &&
                            obj[key] !== "-"
                              ? {
                                  color: token?.Typography?.baseColorText,
                                }
                              : null
                          }
                          target={key === "Address" ? "_blank" : null}
                          href={
                            key === "Address"
                              ? getAddressMapsUrl(`${obj[key]}`)
                              : `tel:${obj[key]}`
                          }>
                          {obj[key] ? obj[key] : ""}
                        </Typography.Link>
                      ) : (
                        <Typography.Text className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-cont__title">
                          {obj[key] ? obj[key] : ""}
                        </Typography.Text>
                      )}
                      {!editOrderDetails[key] && !OrderIsNotEditable ? (
                        <Button
                          type="text"
                          size="small"
                          onClick={() => {
                            handleEditOrderDetails(key);
                          }}
                          icon={
                            <EditTwoTone
                              twoToneColor={token?.icons?.baseColor}
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          }
                        />
                      ) : null}
                    </>
                  ) : (
                    <>
                      {editOrderDetails[key] ? (
                        <>
                          {key === "Promotion Code" ? (
                            (loading || globalLoading || isLoading) &&
                            updatedField === "Promotion Code" ? (
                              <Skeleton.Input
                                className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-cont-withIcon__sekeletonInp"
                                style={{
                                  width: "100%",
                                }}
                                active
                                size={"small"}
                              />
                            ) : (
                              <Select
                                placeholder={key}
                                style={{
                                  width: "100%",
                                }}
                                value={
                                  valOfOrderDetails[key]
                                    ? valOfOrderDetails[key]?.discount_code
                                    : undefined
                                }
                                options={[
                                  ...possibleDiscounts,
                                  {
                                    discount_code: "Unassign promotion code",
                                    id: "Unassign promotion code",
                                  },
                                ]?.map((discount) => ({
                                  label: discount?.discount_code,
                                  value: discount?.id,
                                }))}
                                onChange={(val) => {
                                  const foundDiscount = possibleDiscounts?.find(
                                    (discount) => discount?.id === val
                                  );
                                  if (error && error["discount"]) {
                                    dispatch(resetError("discount"));
                                  }
                                  if (foundDiscount) {
                                    handleValsOfOrderDetails(
                                      key,
                                      foundDiscount
                                    );
                                  } else {
                                    handleValsOfOrderDetails(key, {
                                      discount_code: "Unassign promotion code",
                                      id: "Unassign promotion code",
                                    });
                                  }
                                }}
                                status={
                                  error && error["discount"] ? "error" : null
                                }
                              />
                            )
                          ) : null}
                          <Button
                            type="text"
                            size="small"
                            onClick={() => {
                              if (key === "Promotion Code") {
                                if (error && error["discount"]) {
                                  dispatch(resetError("discount"));
                                }
                                const discount = order?.discount
                                  ? order?.discount
                                  : null;
                                handleValsOfOrderDetails(key, discount);
                              }
                              handleEditOrderDetails(key);
                            }}
                            disabled={isLoading || loading}
                            icon={
                              <CloseCircleOutlined
                                style={{
                                  fontSize: "18px",
                                }}
                              />
                            }
                          />
                          <Button
                            type="text"
                            size="small"
                            disabled={isLoading || loading || error?.discount}
                            icon={
                              <CheckCircleTwoTone
                                twoToneColor={token?.icons?.baseColor}
                                onClick={() => {
                                  if (key === "Promotion Code") {
                                    if (
                                      valOfOrderDetails[key]?.id ===
                                      "Unassign promotion code"
                                    ) {
                                      updateOrderPromotionCode({
                                        id: order?.id,
                                        discount: null,
                                      });
                                    } else {
                                      updateOrderPromotionCode({
                                        id: order?.id,
                                        discount: valOfOrderDetails[key],
                                      });
                                    }
                                  }
                                  setupdatedField(key);
                                }}
                                style={{
                                  fontSize: "18px",
                                }}
                              />
                            }
                          />
                        </>
                      ) : (
                        <Typography.Text
                          className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-cont__title"
                          style={
                            key === "driver" && obj[key] !== "is not exist"
                              ? {
                                  color: token?.Typography?.baseColorText,
                                }
                              : null
                          }>
                          {obj[key] ? obj[key] : ""}
                        </Typography.Text>
                      )}
                      {!editOrderDetails[key] &&
                      !OrderIsNotEditable &&
                      key === "Promotion Code" ? (
                        <Button
                          type="text"
                          size="small"
                          onClick={() => {
                            handleEditOrderDetails(key);
                          }}
                          icon={
                            <EditTwoTone
                              twoToneColor={token?.icons?.baseColor}
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          }
                        />
                      ) : null}
                    </>
                  )}
                </div>
              </div>
              {key !== "Excise Tax" ? (
                <Divider
                  className="order-drawer__collapseCont__collapse__ifo-cont__divider"
                  type="horizontal"
                />
              ) : null}
            </div>
          );
        }
        res.push(element);
      } else {
        const newObj = obj[key];
        let element = (
          <div
            className="order-drawer__collapseCont__collapse__ifo-cont"
            key={key}>
            <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine">
              <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__key-cont">
                <Typography.Text className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__key-cont__title">
                  Customer
                </Typography.Text>
              </div>
              <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-contCustomer">
                <Link
                  onClick={() => {
                    setselectedTabKey("all");
                    getOrdersWithParams({ patient_id: newObj.id });
                  }}
                  className="patient-orders-count"
                  to={`/admin/orders/all/${newObj.id}`}>
                  <Typography.Text
                    className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-cont__title"
                    style={{
                      color: token?.Typography?.baseColorText,
                    }}>
                    {newObj["full_name"]}
                  </Typography.Text>
                </Link>
              </div>
            </div>
            <Divider
              className="order-drawer__collapseCont__collapse__ifo-cont__divider"
              type="horizontal"
            />
          </div>
        );
        let blackListElement = (
          <div
            className="order-drawer__collapseCont__collapse__ifo-cont"
            key={"black_list"}>
            <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine">
              <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__key-cont">
                <Typography.Text className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__key-cont__title">
                  Black List
                </Typography.Text>
              </div>
              <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-contCustomer">
                {newObj?.blacklist_reason && newObj?.blacklist ? (
                  <Typography.Text className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-cont__title">
                    {newObj?.blacklist_reason}
                  </Typography.Text>
                ) : null}
                {newObj?.blacklist ? (
                  <div className="order-drawer__collapseCont__collapse__ifo-cont__firstLine__value-contCustomer__wrapper">
                    <Button
                      style={{
                        width: "fit-content",
                      }}
                      size="small"
                      type="primary"
                      loading={loading || orderLoading}
                      danger
                      onClick={() => {
                        setIsBlackListModalOpened(true);
                      }}>
                      Edit BL Reason
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      loading={loading || orderLoading}
                      onClick={() => {
                        setIsConfirmOpened(true);
                      }}>
                      Reinstate
                    </Button>
                  </div>
                ) : (
                  <Button
                    style={{
                      width: "fit-content",
                    }}
                    size="small"
                    type="primary"
                    loading={loading || orderLoading}
                    danger
                    onClick={() => {
                      setIsBlackListModalOpened(true);
                    }}>
                    Add To Black List
                  </Button>
                )}
              </div>
            </div>
            <Divider
              className="order-drawer__collapseCont__collapse__ifo-cont__divider"
              type="horizontal"
            />
          </div>
        );
        res.push(element);
        res.push(blackListElement);
      }
    }
    return res;
  };

  const items = objectToArray(information);
  useEffect(() => {
    if (order) {
      const newTableData = (order?.order_items || []).map((el) => ({
        image: el?.picture_url ? el?.picture_url : "",
        name: el?.name ? el?.name : "",
        count: el?.quantity ? el?.quantity : "",
        unit: el?.variant?.name ? el?.variant?.name : "",
        price: el?.variant?.price ? `${el?.variant?.price}` : "",
        key: el?.id,
      }));
      setTableData(newTableData);
      let orderInfo = {};
      const {
        first_name,
        last_name,
        state,
        city,
        phone_number,
        id,
        blacklist,
        blacklist_reason,
      } = order?.customer ? order?.customer : {};

      const orderHaveDriever = drivers?.find(
        (el) => el?.id === Number(order?.driver)
      );
      setAssignedDriver(
        orderHaveDriever
          ? `${orderHaveDriever?.first_name} ${orderHaveDriever?.last_name}`
          : null
      );
      setOrderStatus(Utils?.statusNameMapping(order?.status));
      setPaymentType(order?.payment_type);
      const dateTime = order?.created_at
        ? Utils?.getFormattedDateTime(order?.created_at)?.split(" ")
        : "";
      const date = dateTime[0];
      const time = Utils?.convertToAmPm(dateTime[1]);
      orderInfo = {
        id: order?.id ? order?.id : "-",
        number: order?.order_id ? order?.order_id : "-",
        customer: {
          full_name: `${first_name} ${last_name}`,
          state,
          city,
          id,
          blacklist,
          blacklist_reason: blacklist_reason ? blacklist_reason : "",
        },
        "Id image": order?.customer?.id_image_url
          ? order?.customer?.id_image_url
          : "-",
        "Medical image": order?.customer?.med_rec_image_url
          ? order?.customer?.med_rec_image_url
          : "-",
        Address: order?.address ? order?.address : "-",
        "Phone Number": phone_number ? phone_number : "-",
        date: order?.created_at ? date + " " + time : "-",
        driver: order?.driver
          ? `${orderHaveDriever?.first_name} ${orderHaveDriever?.last_name}`
          : "-",
        "Promotion Code": order?.discount?.discount_code
          ? order?.discount?.discount_code
          : "-",
        discount:
          order?.currency && order?.discount_total
            ? `${order.currency} ${order.discount_total}`
            : order?.currency && order?.discount_total
            ? order?.currency
            : "-",
        "Tax Total": order?.tax_total
          ? `${order.currency} ${order?.tax_total}`
          : "-",
        "Sales Tax": order?.sales_tax
          ? `${order.currency} ${order?.sales_tax} `
          : "-",
        "Excise Tax": order?.excise_tax
          ? `${order.currency} ${order?.excise_tax} `
          : "-",
      };
      setInforMation(orderInfo);
      setValsOfOrderDetails({
        Address: "",
        "Phone Number": order?.customer?.phone_number
          ? order?.customer?.phone_number
          : "+1",
        "Promotion Code": order?.discount ? order?.discount : null,
      });
      setNote(order?.customer_note);
    }
  }, [order]);

  useEffect(() => {
    if (order?.customer?.id) {
      dispatch(getPatientShippingAddresses({ id: order?.customer?.id }));
    }
  }, [order?.customer?.id]);

  useEffect(() => {
    const driveritems = drivers?.map((el) => ({
      key: el?.id,
      label: `${el?.first_name} ${el?.last_name}`,
    }));
    setDropdownItemsDriver(driveritems);
  }, [drivers]);

  const ammendOrder = (params) => {
    params = {
      driver: params?.id,
      id: order?.id,
    };
    dispatch(updateOrderDriver(params));
  };

  const ammendOrderStatus = (params) => {
    params = {
      status: params?.status,
      id: order?.id,
    };
    dispatch(updateOrderStatus(params));
  };

  const amendPaymentType = (params) => {
    params = {
      payment_type: params?.payment_type,
      id: order?.id,
    };
    dispatch(updateOrderPaymentType(params));
  };

  const ammendGrandToatal = (params) => {
    params = {
      grand_total: params?.grand_total,
      id: order?.id,
    };
    dispatch(updateGrandTotal(params));
  };

  const updateCustomerNote = (params) => {
    dispatch(
      patchCustomerNote({
        id: params?.id ? params?.id : order?.id,
        customer_note: params?.customer_note ? params?.customer_note : note,
        ...params,
      })
    )?.then((res) => {
      if (res?.type === "patch_customer_note/fulfilled") {
        set_is_note_text_area_visible(false);
      } else if (res?.type === "patch_customer_note/rejected") {
        if (typeof res?.payload === "object" && !Array.isArray(res?.payload)) {
          for (let key in res?.payload) {
            if (
              res?.payload[key] &&
              res?.payload[key][0] &&
              typeof res?.payload[key][0] === "string"
            ) {
              if (key === "customer_note") {
                openNotificationWithIcon("error", key, res?.payload[key]);
                break;
              }
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    if (!isOpened) {
      setSecondCollapseActiveKey(["item"]);
      setFirstCollapseActiveKey(["table"]);
      setThirthCollapseActiveKey([]);
    }
  }, [isOpened]);

  const onBlackListModalOk = (params) => {
    dispatch(
      updatePatient({
        ...params,
        id: information?.customer?.id,
        blacklist: true,
      })
    )?.then((res) => {
      if (res?.type === "updatePatient/fulfilled") {
        if (res?.payload) {
          setInforMation({
            ...information,
            customer: {
              ...information?.customer,
              full_name: `${
                res?.payload?.first_name ? res?.payload?.first_name : ""
              } ${res?.payload?.last_name ? res?.payload?.last_name : ""}`,
              state: res?.payload?.state ? res?.payload?.state : "",
              city: res?.payload?.city ? res?.payload?.city : "",
              id: res?.payload?.id ? res?.payload?.id : "",
              blacklist: res?.payload?.blacklist ? res?.payload?.blacklist : "",
              blacklist_reason: res?.payload?.blacklist_reason
                ? res?.payload?.blacklist_reason
                : "",
            },
          });
        }
        dispatch(resetPatientError());
      }
    });
  };

  const removeFromBL = (params) => {
    dispatch(updatePatient(params))?.then((res) => {
      if (res?.type === "updatePatient/fulfilled") {
        if (res?.payload) {
          setInforMation({
            ...information,
            customer: {
              ...information?.customer,
              full_name: `${
                res?.payload?.first_name ? res?.payload?.first_name : ""
              } ${res?.payload?.last_name ? res?.payload?.last_name : ""}`,
              state: res?.payload?.state ? res?.payload?.state : "",
              city: res?.payload?.city ? res?.payload?.city : "",
              id: res?.payload?.id ? res?.payload?.id : "",
              blacklist: res?.payload?.blacklist ? res?.payload?.blacklist : "",
              blacklist_reason: res?.payload?.blacklist_reason
                ? res?.payload?.blacklist_reason
                : "",
            },
          });
        }
        dispatch(resetPatientError());
      }
    });
    setIsConfirmOpened(false);
  };

  return {
    token,
    tableColumns,
    tableData,
    items,
    dropdownItemsDriver,
    dropdownItemsActions,
    dropdownItemsCards,
    assignedDriver,
    // setAssignedDriver,
    ammendOrder,
    drivers,
    orderStatus,
    setOrderStatus,
    paymentType,
    information,
    // setInforMation,
    contextHolder,
    setCurrentField,
    currentField,
    ammendOrderStatus,
    isEditTotalOPened,
    setIsEditTotalOpened,
    inpVal,
    setInpVal,
    amendPaymentType,
    dispatch,
    openNotificationWithIcon,
    ammendGrandToatal,
    isSmallDesktop,
    isMobile,
    secondCollapseActiveKey,
    setSecondCollapseActiveKey,
    firstCollapseActiveKey,
    setFirstCollapseActiveKey,
    setEditOrderDetails,
    setValsOfOrderDetails,
    options,
    resetError,
    resetPatientError,
    patientError,
    dispatch,
    patientError,
    setupdatedField,
    updatedField,
    selectedRowKey,
    handleAddItem,
    setIsDeleteOrderItemModalOpened,
    isDeleteOrderItemModalOpened,
    setOrderItemMode,
    setselectedRowKey,
    orderItemMode,
    deleteOrderItem,
    selectedMenuItem,
    receivedMenuItems,
    selectedVariant,
    menuItemCount,
    setTableData,
    setSelectedMenuItem,
    setSelectedVariant,
    setMenuItemCount,
    setSearchMenuItemVal,
    OrderIsNotEditable,
    error,
    selectedMenu,
    setSelectedMenu,
    selectedSubMenu,
    setSelectedSubMenu,
    setThirthCollapseActiveKey,
    thirthCollapseActiveKey,
    is_note_text_area_visible,
    set_is_note_text_area_visible,
    note,
    setNote,
    updateCustomerNote,
    isBlackListModalOpened,
    setIsBlackListModalOpened,
    onBlackListModalOk,
    isConfirmOpened,
    setIsConfirmOpened,
    removeFromBL,
  };
}
